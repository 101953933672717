import React, { Component } from 'react';
import Divider from '../../ui/divider/Divider';

class Introblock extends Component {
    constructor(props) {
        super(props);

        this.state = {
            mapClass: '',
            infoClass: ''
        };

        // this.renderImage = require('../../../assets/images/points/page/bitmap.png');
        this.renderImage = this.props.activePointResourses.podImage;
        
        this.message1 = this.props.activePointResourses.message1;
        this.message2 = this.props.activePointResourses.message2;
        this.message3 = this.props.activePointResourses.message3;

        this.mapImage = require('../../../assets/images/points/page/map_mockup.jpg');
    }

    onMouseEnter = (e) => {

        this.setState({
            mapClass: 'hover',
            infoClass: 'hidden'
        });

    } 

    onMouseLeave = (e) => {

        this.setState({
            mapClass: '',
            infoClass: ''
        });

    }

    render() {
        return (
            <div className="intro-block-container">
                <Divider />

                <div className="intro-panel-container">
                    {/* <div className={`map-panel ${this.state.mapClass}`} onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
                        <div className="touch-element">
                            <div className="inner-circle"></div>
                        </div>
                        <div className="map-container">
                            <img src={this.mapImage} alt="map render"></img>
                        </div>
                    </div> */}
                    <div className={`info-panel ${this.state.infoClass}`}>
                        <div className="go-back-button" onClick={() => {this.props.changePage({page: 'aboutpage', aboutPageSlide: 'landscape'})}}>
                            <div className="animation-mask">
                                <div className="arrow-container">
                                    <img className="arrow-image" alt="arrow"></img>
                                </div>
                                <div className="label-container">
                                    <div className="label">Back to map</div>
                                </div>
                            </div>
                        </div>
                        <div className="center-message-1">
                            <div className="row-container">
                                <div className="animation-mask order-1">
                                    <div className="text-container">{this.message1}</div>
                                </div>
                            </div>
                            <div className="row-container">
                                <div className="animation-mask order-2">
                                    <div className="text-container">{this.message2}</div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="center-message-2">
                            <div className="row-container">
                                <div className="animation-mask order-1">
                                    <div className="text-container">(Bebot Art Theme)</div>
                                </div>
                            </div>
                        </div> */}
                        <div className="center-message-3">
                            <div className="row-container">
                                <div className="animation-mask order-1">
                                    <div className="text-container">{this.message3}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="pod-render-container">
                        <div className="background-image"></div>
                        <div className="render-image">
                            <img src={this.renderImage} alt="pod render"></img>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}

export default Introblock;
