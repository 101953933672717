import React, { Component } from 'react';
import Main from './components/main';
import './App.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

class App extends Component {
  constructor(props) {
    super(props);

    this.theme = createMuiTheme({
      palette: {
        primary: { main: '#111111' }, 
        secondary: { main: '#111111' }
      },
      typography: { useNextVariants: true }
    });
  }

  render() {
    return (
      <div className="App">
        <MuiThemeProvider theme={this.theme}>
          <Main />
        </MuiThemeProvider>
      </div>
    );
  }
}

export default App;
