import React, { Component } from 'react';

import Header from '../ui/header/Header';

import Introblock from './introblock/Introblock';
// import Explorationblock from './explorationblock/Explorationblock';

class Podpage extends Component {
    constructor(props) {
        super(props);

        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;

        this.isMobile = this.screenWidth > 1024 ? false : true;

        this.points = [
            {
                point: 'point-1',
                introBlock: {
                    message1: 'Boutique',
                    message2: 'Pod',
                    message3: 'Experience the curated collections at our stylish Pod Park Boutique.',
                    podImage: require('../../assets/images/points/Pod01_Draft.png')
                }
            },
            {
                point: 'point-2',
                introBlock: {
                    message1: 'Fitness ',
                    message2: 'Pod',
                    message3: 'Wellness done daily at our state of the art Fitness Pod.',
                    podImage: require('../../assets/images/points/pod03_fitness.png')
                }
            },
            {
                point: 'point-3',
                introBlock: {
                    message1: 'Fitness',
                    message2: 'Pod',
                    message3: 'Challenge your inner rider with spin classes that offer just the right balance of energy and strength.',
                    podImage: require('../../assets/images/points/pod03_fitness.png')
                }
            },
            {
                point: 'point-4',
                introBlock: {
                    message1: 'Restaurant',
                    message2: 'Pod',
                    message3: 'Feeling hungry? A visit to one of our many restaurant pods is sure to cure your cravings.',
                    podImage: require('../../assets/images/points/pod10_restaurant.png')
                }
            },
            {
                point: 'point-5',
                introBlock: {
                    message1: 'Coffee',
                    message2: 'Pod',
                    message3: 'From lattes to frappes to cold brews, a quick stop to our coffee pod is sure to fix your caffeine cravings.',
                    podImage: require('../../assets/images/points/podCoffee.png')
                }
            },
            {
                point: 'point-6',
                introBlock: {
                    message1: 'Bar Pod',
                    message2: '',
                    message3: "Stop by our trendy bar pods to see what cocktails we've created for you.",
                    podImage: require('../../assets/images/points/pod07_bar.png')
                }
            },
            {
                point: 'point-7',
                introBlock: {
                    message1: 'Bar Pod',
                    message2: '',
                    message3: 'We’re serving up your favorites at Pod Park, from fine wines to the latest and greatest on tap.',
                    podImage: require('../../assets/images/points/pod07_bar.png')
                }
            },
            {
                point: 'point-8',
                introBlock: {
                    message1: 'Fitness',
                    message2: 'Pod',
                    message3: 'Add yoga to your routine with sessions for every student, from challenging and intensive to relaxing and restorative.',
                    podImage: require('../../assets/images/points/pod03_fitness.png')
                }
            },
            {
                point: 'point-9',
                introBlock: {
                    message1: 'Restaurant',
                    message2: 'Pod',
                    message3: 'Enjoy upscale dining in an untraditional setting, the best of both worlds for a memorable night out.',
                    podImage: require('../../assets/images/points/pod10_restaurant.png')
                }
            },
            {
                point: 'point-10',
                introBlock: {
                    message1: 'Restaurant',
                    message2: 'Pod',
                    message3: 'If you’re in the mood to grab and go, pick up food fast so you have time to savor it slow.',
                    podImage: require('../../assets/images/points/pod10_restaurant.png')
                }
            },
            {
                point: 'point-11',
                introBlock: {
                    message1: 'Restaurant',
                    message2: 'Pod',
                    message3: 'Tempt your taste buds with a night at Pod Park where five restaurants to choose from means endless possibilities.',
                    podImage: require('../../assets/images/points/pod10_restaurant.png')
                }
            },
            {
                point: 'point-12',
                introBlock: {
                    message1: 'Restaurant ',
                    message2: 'Pod',
                    message3: 'If traditional Philly food is on your mind, head to our restaurant pods and enjoy some of your favorites done right.',
                    podImage: require('../../assets/images/points/pod10_restaurant.png')
                }
            }
        ];

        let activePoint = this.points[0];

        for(let point of this.points) {
            if(point.point === this.props.podPointId) {
                activePoint = point;
            }
        }

        this.state = {
            headerClass: 'dark',
            imagesSlideState: {
                first: 0,
                second: 0
            },
            activePoint: activePoint
        };

        console.log(activePoint);
    }

    handleScroll = (event) => {
        const top = event.target.scrollTop;

        //
        let firstSlide = 0;
        const firstSlideTop = this.screenHeight*0.3;
        const firstSlideBottom = this.screenHeight*0.7;

        let secondSlide = 0;
        const secondSlideTop = this.screenHeight*0.8;
        const secondSlideBottom = this.screenHeight*1.25;

        if(!this.isMobile) {
            if(top >= firstSlideTop && top <= firstSlideBottom) {
                firstSlide = 100 * (top - firstSlideTop)/(firstSlideBottom - firstSlideTop);
            } else if(top > firstSlideBottom) {
                firstSlide = 100;
            }
    
            if(top >= secondSlideTop && top <= secondSlideBottom) {
                secondSlide = 100 * (top - secondSlideTop)/(secondSlideBottom - secondSlideTop);
            } else if(top > secondSlideBottom) {
                secondSlide = 100;
            }
        } else {
            firstSlide = 100;
            secondSlide = 100;
        }
        
         
        //
        let headerClass = 'dark';

        if (top > 0) {
            headerClass = 'scrolled';
        } 

        this.setState({
            headerClass: headerClass,
            imagesSlideState:  {
                first: firstSlide,
                second: secondSlide
            }
        });
    }

    changePage = (page) => {
        if(page && this.props.changePage) {
            this.props.changePage(page);
        }
    }

    render() {
        
        return (
            <div className={`pod-page-container ${this.props.class}`}>
                
                <Header changePage={this.changePage} headerClass={this.state.headerClass}/>

                <Introblock changePage={this.changePage} activePointResourses={this.state.activePoint.introBlock}/>
                {/* <Explorationblock imagesSlideState={this.state.imagesSlideState}/> */}

            </div>
        );
    }
}

export default Podpage;
