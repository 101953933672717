import React, { Component } from 'react';

import Background from './background/Background';
// import Dragelement from './dragelement/Dragelement';

import Header from '../ui/header/Header';

// import Footer from '../ui/footer/Footer';

class Frontpage extends Component {

    changePage = (page) => {
        if(page && this.props.changePage) {
            this.props.changePage(page);
        }
    }

    explorePodButton = () => {
        this.changePage({page: 'aboutpage'});
    }

    render() {

        return (
            <div className={`frontpage-container ${this.props.class}`}>

                <Header changePage={this.changePage}/>

                <div className="center-block">
                    <div className="center-message-1">
                        <div className="row-container">
                            <div className="animation-mask order-1">
                                <div className="text-container">Uniquely</div>
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="animation-mask order-2">
                                <div className="text-container">Northern Liberties</div>
                            </div>
                        </div>
                    </div>
                    <div className="center-message-2">
                        <div className="row-container">
                            <div className="animation-mask order-1">
                                <div className="text-container">The latest addition to the mixed-use development at The Piazza, Pod Park introduces</div>
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="animation-mask order-2">
                                <div className="text-container">a new approach to retail, food and beverage, and recreational programming in Philadelphia.</div>
                            </div>
                        </div>
                        <div className="row-container">
                            <div className="animation-mask order-3">
                                <div className="text-container">This unique collection offers visitors experiences unlike any existing concept in Philadelphia.</div>
                            </div>
                        </div>
                    </div>
                    <div className="explore-button-container">
                        <div className="explore-button" onClick={this.explorePodButton}>EXPLORE POD PARK</div>
                    </div>
                    {/* <Dragelement changePage={this.changePage}/> */}
                </div>
                
                <div className="center-block-mobile">
                    <div className="center-message-1">
                        <p>Uniquely</p>
                        <p>Northern</p>
                        <p>Liberties</p>
                    </div>
                    <div className="center-message-2">
                        The latest addition to the mixed-use development at The Piazza, Pod Park introduces a new approach to retail, food and beverage, and recreational programming in Philadelphia. This unique collection offers visitors experiences unlike any existing concept in Philadelphia.
                    </div>
                </div>

                {/* <Footer /> */}

                <Background />
            </div>  
        );
    }
}

export default Frontpage;
