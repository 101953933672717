import React, { Component } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

class MobileView extends Component {
    constructor(props) {
        super(props);

        this.scene = props.scene;

        this.state = {
            area: 'restaurant'
        };

        this.selecStyle = {
            width: '280px',
            height: '50px'
        };
    }

    componentDidMount() {
        this.props.updateHover && this.props.updateHover(this.state.area);
    }

    onChangeHandler = (event) => {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.props.updateHover && this.props.updateHover(value);

        this.setState({
            [name]: value
        });
    }

    pointClickHandler = (point) => {
        this.props.changePage(point);
    }

    getPods = (area) => {
        const pods = [];

        for(let point of this.scene.details) {

            if(point.area === area) {

                let layout = (
                                <div key={`point-${point.point}`} className="pod-item" onClick={() => {this.pointClickHandler(point);}}>
                                    <div className="inner-container">
                                        <div className="image-container">
                                            <img src={point.image} alt={`point-${point.point}`}></img>
                                        </div>
                                        <div className="info-container">
                                            <div className="pod-name">{point.name}</div>
                                            <div className="pod-info">{point.text}</div>
                                        </div>
                                        <div className="arrow-container">></div>
                                    </div>
                                </div>
                            );

                pods.push(layout);

            }

        }

        return pods;
    }

    render() {
        return (
            <div className="mobile-landscape-viewer">
                <div className="title-container">Select one of 4 areas to see offers</div>
                <div className="select-area-container">
                    <Select value={this.state.area} name="area" style={this.selecStyle} onChange={this.onChangeHandler}>
                        <MenuItem value="restaurant">
                            <div className="area-option">
                                <div className="marker-box restaurant"></div>
                                <div className="area-name">Restaurant Alley</div>
                            </div>
                        </MenuItem>
                        <MenuItem value="whimsey">
                            <div className="area-option">
                                <div className="marker-box whimsey"></div>
                                <div className="area-name">Whimsey</div>
                            </div>
                        </MenuItem>
                        <MenuItem value="detox">
                            <div className="area-option">
                                <div className="marker-box detox"></div>
                                <div className="area-name">Detox + Retox</div>
                            </div>
                        </MenuItem>
                        <MenuItem value="yoga">
                            <div className="area-option">
                                <div className="marker-box yoga"></div>
                                <div className="area-name">Yoga + Coffe</div>
                            </div>
                        </MenuItem>
                    </Select>
                </div>
                <div className="pod-list-container">
                    {this.getPods(this.state.area)}
                </div>
                <div className="expirience-message-container">
                    <div className="expirience-message">
                        <div className="icon-container">
                            <img alt="monitor-icon" src={require("../../../../../assets/images/monitor_icon.svg")}></img>
                        </div>
                        <div className="message-container">
                            Use desktop or laptop for better expirience
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default MobileView;
