import React, { Component } from 'react';
import Divider from '../../ui/divider/Divider';

class Titleblock extends Component {
    render() {
        
        return (
            <div className="title-block-container">
                
                <Divider />

                <div className="background-container">
                    <img className="background" alt="background"></img>
                </div>

                <div className="title-container">
                    <div className="center-image">
                        <div className="row-container">
                            <div className="animation-mask">
                                {/* <img className="image-container" alt="piazza"></img> */}
                                <div className="title-label">Uniquely Northern Liberties</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="center-message-1">
                        <div className="row-container">
                            <div className="animation-mask">
                                <div className="text-container">OVERVIEW</div>
                            </div>
                        </div>
                    </div> */}
                </div>

            </div>
        );
    }
}

export default Titleblock;
