import React, { Component } from 'react';

import * as PIXI from 'pixi.js';
import * as TWEEN from '@tweenjs/tween.js';

import MobileView from './mobileview/MobileView';
// import Legend from './legend/Legend';

class Viewer extends Component {
    constructor(props) {
        super(props);

        this.devicePixelRatio = window.devicePixelRatio || 1;

        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;
        
        this.isTablet = (this.screenWidth > 768 && this.screenWidth <= 1024) ? true : false;
        this.isMobile = this.screenWidth <= 768 ? true : false;

        this.globalScale = 1;
        this.tabletScale = 1;

        if(this.isTablet) {
            this.globalScale = 1;
            this.tabletScale = 0.8;
        }

        this.hoverScale = {
            x: 1000,
            y: 560
        };

        this.areaElements = [];

        this.scene = {
            mainImage: require('../../../../assets/images/landscape/map.jpg'),
            areas: [
                {
                    name: 'Restaurant Alley',
                    areaId: 'restaurant',
                    url: '',
                    image: require('../../../../assets/images/landscape/Zone02.png'),
                    hoverArea: require('../../../../assets/images/landscape/Zone02_hover.png')
                },
                {
                    name: 'Whimsey',
                    areaId: 'whimsey',
                    url: '',
                    image: require('../../../../assets/images/landscape/Zone03.png'),
                    hoverArea: require('../../../../assets/images/landscape/Zone03_hover.png')
                },
                {
                    name: 'Detox + Retox',
                    areaId: 'detox',
                    url: '',
                    image: require('../../../../assets/images/landscape/Zone04.png'),
                    hoverArea: require('../../../../assets/images/landscape/Zone04_hover.png')
                },
                {
                    name: 'Yoga + Coffe',
                    areaId: 'yoga',
                    url: '',
                    image: require('../../../../assets/images/landscape/Zone01.png'),
                    hoverArea: require('../../../../assets/images/landscape/Zone01_hover.png')
                }
            ],
            points: [
                {
                    name: 'Yoga',
                    number: 1,
                    pointId: 'point-1',
                    area: 'yoga',
                    position: {
                        x: 0.45,
                        y: 0.1
                    },
                    color: '0x81D586'
                },
                {
                    name: 'Detox',
                    number: 2,
                    pointId: 'point-2',
                    area: 'detox',
                    position: {
                        x: 0.07,
                        y: -0.262
                    },
                    color: '0xE6779E'
                },
                {
                    name: 'Yoga',
                    number: 3,
                    pointId: 'point-3',
                    area: 'yoga',
                    position: {
                        x: 0.31,
                        y: -0.15
                    },
                    color: '0x81D586'
                },
                {
                    name: 'Whimsey',
                    number: 4,
                    pointId: 'point-4',
                    area: 'whimsey',
                    position: {
                        x: 0.247,
                        y: -0.26
                    },
                    color: '0xE0B628'
                },
                {
                    name: 'Whimsey',
                    number: 5,
                    pointId: 'point-5',
                    area: 'whimsey',
                    position: {
                        x: 0.18,
                        y: -0.26
                    },
                    color: '0xE0B628'
                },
                {
                    name: 'Detox',
                    number: 6,
                    pointId: 'point-6',
                    area: 'detox',
                    position: {
                        x: -0.153,
                        y: -0.261
                    },
                    color: '0xE6779E'
                },
                {
                    name: 'Detox',
                    number: 7,
                    pointId: 'point-7',
                    area: 'detox',
                    position: {
                        x: 0.015,
                        y: -0.262
                    },
                    color: '0xE6779E'
                },
                {
                    name: 'Detox',
                    number: 8,
                    pointId: 'point-8',
                    area: 'detox',
                    position: {
                        x: -0.22,
                        y: -0.22
                    },
                    color: '0xE6779E'
                },
                {
                    name: 'Restaurant',
                    number: 9,
                    pointId: 'point-9',
                    area: 'restaurant',
                    position: {
                        x: -0.43,
                        y: 0.08
                    },
                    color: '0x6CB3D9'
                },
                {
                    name: 'Detox',
                    number: 10,
                    pointId: 'point-10',
                    area: 'detox',
                    position: {
                        x: -0.085,
                        y: -0.261
                    },
                    color: '0xE6779E'
                },
                {
                    name: 'Yoga',
                    number: 11,
                    pointId: 'point-11',
                    area: 'yoga',
                    position: {
                        x: 0.37,
                        y: -0.04
                    },
                    color: '0x81D586'
                },
                {
                    name: 'Restaurant',
                    number: 12,
                    pointId: 'point-12',
                    area: 'restaurant',
                    position: {
                        x: -0.34,
                        y: -0.077
                    },
                    color: '0x6CB3D9'
                 }
            ],
            labels: [
                {
                    label: 'Communal pod',
                    position: {
                        x: -0.15,
                        y: -0.16
                    }
                },
                {
                    label: 'BeBot (Art)',
                    position: {
                        x: 0.01,
                        y: -0.05
                    }
                },
                {
                    label: 'Parking',
                    position: {
                        x: -0.3,
                        y: -0.4
                    }
                },
                {
                    label: 'Dumpsters',
                    position: {
                        x: -0.125,
                        y: -0.42
                    }
                },
                {
                    label: 'Storage Pod',
                    position: {
                        x: -0.125,
                        y: -0.36
                    }
                },
                {
                    label: 'Restroom Trailer',
                    position: {
                        x: 0.035,
                        y: -0.36
                    }
                },
                {
                    label: 'ADA Restroom Trailer',
                    position: {
                        x: 0.048,
                        y: -0.405
                    }
                },
                {
                    label: 'ThinkCubes (Art)',
                    position: {
                        x: 0.14,
                        y: -0.35
                    }
                },
                {
                    label: 'Nursing Pod',
                    position: {
                        x: 0.29,
                        y: -0.235
                    }
                },
                {
                    label: 'Center Court',
                    position: {
                        x: 0.03,
                        y: 0.4
                    }
                }
            ],
            details: [
                {
                    name: 'Boutique Pod',
                    text: "10 feet long",
                    point: 'point-1',
                    area: 'yoga',
                    offset: {
                        x: -50,
                        y: 0
                    },
                    image: require('../../../../assets/images/points/Pod01_Draft.png'),
                },
                {
                    name: 'Fitness Pod',
                    text: "20 feet long",
                    point: 'point-2',
                    area: 'detox',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    image: require('../../../../assets/images/points/pod03_fitness.png'),
                },
                {
                    name: 'Fitness Pod',
                    text: "20 feet long",
                    point: 'point-3',
                    area: 'yoga',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    image: require('../../../../assets/images/points/pod03_fitness.png'),
                },
                {
                    name: 'Restaurant Pod',
                    text: "20 feet long",
                    point: 'point-4',
                    area: 'whimsey',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    image: require('../../../../assets/images/points/pod10_restaurant.png'),
                },
                {
                    name: 'Coffee Pod',
                    text: "20 feet long",
                    point: 'point-5',
                    area: 'whimsey',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    image: require('../../../../assets/images/points/podCoffee.png'),
                },
                {
                    name: 'Bar Pod',
                    text: "20 feet long",
                    point: 'point-6',
                    area: 'detox',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    image: require('../../../../assets/images/points/pod07_bar.png'),
                },
                {
                    name: 'Bar Pod',
                    text: "20 feet long",
                    point: 'point-7',
                    area: 'detox',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    image: require('../../../../assets/images/points/pod07_bar.png'),
                },
                {
                    name: 'Fitness Pod',
                    text: "40 feet long",
                    point: 'point-8',
                    area: 'detox',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    image: require('../../../../assets/images/points/pod03_fitness.png'),
                },
                {
                    name: 'Restaurant Pod',
                    text: "40 feet long",
                    point: 'point-9',
                    area: 'restaurant',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    image: require('../../../../assets/images/points/pod10_restaurant.png'),
                },
                {
                    name: 'Restaurant Pod',
                    text: "40 feet long",
                    point: 'point-10',
                    area: 'detox',
                    offset: {
                        x: 0,
                        y: 40
                    },
                    image: require('../../../../assets/images/points/pod10_restaurant.png'),
                },
                {
                    name: 'Restaurant Pod',
                    text: "40 feet long",
                    point: 'point-11',
                    area: 'yoga',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    image: require('../../../../assets/images/points/pod10_restaurant.png'),
                },
                {
                    name: 'Restaurant Pod',
                    text: "40 feet long",
                    point: 'point-12',
                    area: 'restaurant',
                    offset: {
                        x: 0,
                        y: 0
                    },
                    image: require('../../../../assets/images/points/pod10_restaurant.png'),
                },
            ]
        };

        this.state = {
            activeArea: ''
        };
    }

    changePage = (details) => {
        if(details && this.props.changePage) {
        
            const obj = {
                page: 'podpage',
                point: details.point
            }

            this.props.changePage(obj);
        }
    }

    componentDidMount() {

        this.width = this.canvasRef.offsetWidth;
        this.height = this.canvasRef.offsetHeight;

        this.app = new PIXI.Application({
            width: this.width,
            height: this.height,
            transparent: false,
            resolution: this.devicePixelRatio,
            antialias: false,
            forceFXAA: false,
            forceCanvas: true,
            backgroundColor: 0x000000,
            autoResize: true
        });

        this.canvasRef.appendChild(this.app.view);

        this.app.start();

        this.renderer = this.app.renderer;

        this.ticker = this.app.ticker;

        this.ticker.add(() => {
            TWEEN.update();
        })


        this.preload();

        this.listeners();
    }

    listeners() {

        window.addEventListener('resize', this.resize);

    }

    preload() {

        const images = [];

        if(!PIXI.loader.resources[this.scene.mainImage]) {
            images.push(this.scene.mainImage);
        }
        
        for(let area of this.scene.areas) {
            if(!images.includes(area.hoverArea) && !PIXI.loader.resources[area.hoverArea]) {
                images.push(area.hoverArea);
            }

            if(!images.includes(area.image) && !PIXI.loader.resources[area.image]) {
                images.push(area.image);
            }
        }

        for(let detail of this.scene.details) {
            if(!images.includes(detail.image) && !PIXI.loader.resources[detail.image]) {
                images.push(detail.image);
            }
        }

        PIXI.loader
            .add(images)
            .load(() => {
                this.sceneInit();
            });
    }

    sceneInit() {

        this.app.stage && this.app.stage.destroy();

        this.app.stage = new PIXI.Container();
        this.app.stage.interactive = true;

        this.mainContainer = new PIXI.Container();
        this.mainContainer.interactive = true;

        // if(this.isTablet) {
        //     this.mainContainer
        //         .on('touchstart', this.onDragStart)
        //         .on('touchend', this.onDragEnd)
        //         .on('touchendoutside', this.onDragEnd)
        //         .on('touchmove', this.onDragMove);
        // }
        
        this.app.stage.addChild(this.mainContainer);

        //background and global scale
        const sprite = new PIXI.Sprite(PIXI.loader.resources[this.scene.mainImage].texture);

        this.scale = this.width / sprite.width;

        this.containerHeight = sprite.height * this.scale;

        this.height = this.containerHeight;

        this.props.updateHeight(`${this.containerHeight}px`);

        this.renderer.resize(this.width, this.containerHeight);

        sprite.setTransform(0, 0, this.scale, this.scale);
        this.mainContainer.addChild(sprite);

        this.mainContainer.setTransform(0, 0, this.globalScale, this.globalScale);
        
        this.dragBorders = {
            x: this.width * (this.globalScale - 1),
            y: this.height * (this.globalScale - 1),
        };

        this.prepareHovers();

        if(!this.isMobile) {
            

            this.prepareLabels();

            this.preparePoints();
    
            this.prepareDetailPanels();

            if(this.isTablet) {
                this.app.stage.on('touchend', (event) => {
                    this.mouseOver(event);
                });
            } else {
                this.app.stage.on('mousemove', (event) => {
                    this.mouseOver(event);
                });
            }
        }
        
    }

    prepareHovers() {

        this.areaElements = [];

        for(let area of this.scene.areas) {

            const areaObj = this.generateAreaObject(area);

            // areaObj.element.setTransform(0, 0, this.tabletScale/this.globalScale, this.tabletScale/this.globalScale);

            this.areaElements.push(areaObj);


            this.mainContainer.addChild(areaObj.element);

        }

    }

    preparePoints() {
        this.mapPoints = [];

        for(let point of this.scene.points) {
            const pointObj = this.generatePointObject(point);

            pointObj.element.setTransform((0.5 + point.position.x) * this.width, (0.5 + point.position.y) * this.height, this.tabletScale/this.globalScale, this.tabletScale/this.globalScale);

            this.mapPoints.push(pointObj);

            this.mainContainer.addChild(pointObj.element);
        }
    }

    prepareLabels() {
        for(let label of this.scene.labels) {
            const labelObj = this.generateLabelObject(label);

            labelObj.element.setTransform((0.5 + label.position.x) * this.width, (0.5 + label.position.y) * this.height, this.tabletScale/this.globalScale, this.tabletScale/this.globalScale);

            this.mainContainer.addChild(labelObj.element);
        }
    }

    prepareDetailPanels() {
        for(let details of this.scene.details) {
            const detailsObj = this.generateDetailsObject(details);

            for(let point of this.mapPoints) {
                if(details.point === point.point.pointId) {

                    detailsObj.position = {
                        x: (0.5 + point.point.position.x) * this.width + details.offset.x,
                        y: (0.5 + point.point.position.y) * this.height - 35 + details.offset.y
                    }

                    detailsObj.element.setTransform(detailsObj.position.x, detailsObj.position.y, this.tabletScale/this.globalScale, this.tabletScale/this.globalScale);


                    point.innerCircle.on('mouseover', () => {

                        point.innerCircle.tint = 0xffffff;
                        point.pointNumber.style.fill = point.point.color;

                        point.innerCircle.tween = new TWEEN.Tween({x: 0}).to({x: 1}, 250)
                            .easing(TWEEN.Easing.Quadratic.Out)
                            .onUpdate((alpha) => {
                                // point.pointName.alpha = alpha.x;
                                // point.pointName.setTransform(17 + (15 * alpha.x));

                                detailsObj.element.alpha = alpha.x;
                                detailsObj.element.setTransform(detailsObj.position.x, detailsObj.position.y + 15 - 15*alpha.x, 0.95 + 0.05*alpha.x, 0.95 + 0.05*alpha.x);
                            })
                            .onComplete(() => {
                                point.innerCircle.tween = null;
                            })
                            .start(); 
  
                    });
            
                    point.innerCircle.on('mouseout', () => {
                        if(point.innerCircle.tween) {
                            point.innerCircle.tween.stop();
                            point.innerCircle.tween = null;
                        }
                       
                        point.innerCircle.tint = point.point.color;
                        point.pointNumber.style.fill = 0xffffff;
                        // point.pointName.alpha = 0;

                        detailsObj.element.alpha = 0;
                    });

                    point.innerCircle.on('pointerup', () => {
                        this.changePage(details);
                    });

                }
                
            }

            this.mainContainer.addChild(detailsObj.element);
        }
    }

    generateAreaObject(area) {

        const element = new PIXI.Container();

        const hoverImage = new PIXI.Sprite(PIXI.loader.resources[area.hoverArea].texture);

        const pixels = this.renderer.extract.pixels(hoverImage);
        const alpha = pixels.filter((pix,i) => { 
            return i%4 === 3;
        });

        const areaImage = new PIXI.Sprite(PIXI.loader.resources[area.image].texture);

        const scale = this.width / areaImage.width;

        areaImage.setTransform(0, 0, scale, scale);

        element.addChild(areaImage);

        if(this.state.activeArea !== area.areaId) {
            element.alpha = 0;
        }
        

        const obj = {
            area: area,
            alpha: alpha,
            element: element,
            active: false
        }

        return obj;

    }

    generateLabelObject(label) {

        const element = new PIXI.Container();

        const labelName = new PIXI.Text(label.label, {fontFamily : 'GothamProLight', fontSize: 13, fill: 0xffffff, align: 'center', strokeThickness: 1});
        labelName.anchor.set(0.5, 0);

        labelName.resolution = 2;

        const width = labelName.getBounds().width + 24;

        const box = new PIXI.Graphics();

        box.beginFill(0x222222, 0.85);
        box.drawRoundedRect(-width/2, -6, width, 30, 4);
        box.endFill();

        const arrow = new PIXI.Graphics();
        arrow.beginFill(0x222222, 0.8);
        arrow.moveTo(0, 29);
        arrow.lineTo(8, 23);
        arrow.lineTo(-8, 23);
        arrow.endFill();


        element.addChild(box);
        element.addChild(arrow);
        element.addChild(labelName);

        //
        const obj = {
            element: element,
            labelName: labelName,
            box: box,
            arrow: arrow
        }

        return obj;

    }

    generatePointObject(point) {

        const element = new PIXI.Container();

        const outerCircle = new PIXI.Graphics();

        outerCircle.lineStyle(1, 0xffffff);
        outerCircle.drawCircle(0, 0, 25);
        
        const innerCircle = new PIXI.Graphics();

        innerCircle.beginFill(0xffffff);
        innerCircle.drawCircle(0, 0, 14);
        innerCircle.endFill();

        innerCircle.tint = point.color;

        const borderCircle = new PIXI.Graphics();
        borderCircle.lineStyle(1, 0xffffff);
        borderCircle.drawCircle(0, 0, 14);


        const pointNumber = new PIXI.Text(point.number, {fontFamily : 'GothamProRegular', fontSize: 12, fill: 0xffffff, align: 'center'});
        pointNumber.anchor.set(0.5, 0.5);

        // const pointName = new PIXI.Text(point.name, {fontFamily : 'GothamProRegular', fontSize: 13, fill: 0xffffff, align: 'start'});
        // pointName.anchor.set(0, 0.5);
        // pointName.setTransform(32);
        // pointName.alpha = 0;

        element.addChild(outerCircle);
        // element.addChild(pointName);
        element.addChild(innerCircle);
        element.addChild(pointNumber);
        element.addChild(borderCircle);


        //
        innerCircle.buttonMode = true;
        innerCircle.interactive = true;

        //
        const obj = {
            point: point,
            element: element,
            innerCircle: innerCircle,
            outerCircle: outerCircle,
            borderCircle: borderCircle,
            pointNumber: pointNumber,
            // pointName: pointName
        }

        return obj;

    }

    generateDetailsObject(detail) {
        const element = new PIXI.Container();

        const detailName = new PIXI.Text(detail.name + ' >', {fontFamily : 'GothamProLight', fontSize: 16, fill: 0x222222, align: 'center', strokeThickness: 1, letterSpacing: 1});
        detailName.anchor.set(0.5, 1);
        detailName.setTransform(0, -68);

        detailName.resolution = 4;

        const detailText = new PIXI.Text(detail.text, {fontFamily : 'GothamProLight', fontSize: 14, fill: 0x222222, align: 'center', strokeThickness: 0.5});
        detailText.anchor.set(0.5, 1);
        detailText.setTransform(0, -45);

        detailText.resolution = 4;

        const width = 220;
        const height = 135;

        const box = new PIXI.Graphics();

        box.beginFill(0xffffff);
        box.drawRect(-width/2, -height - 7, width, height);
        box.endFill();

        const arrow = new PIXI.Graphics();
        arrow.beginFill(0xffffff);
        arrow.moveTo(0, 0);
        arrow.lineTo(7, -8);
        arrow.lineTo(-7, -8);
        arrow.endFill();


        const image = new PIXI.Sprite(PIXI.loader.resources[detail.image].texture);

        const scale = 180 / image.width;

        image.anchor.set(0.5, 0.5);
        image.setTransform(0, -135, scale, scale);
        

        element.addChild(box);
        element.addChild(arrow);
        element.addChild(image);
        element.addChild(detailName);
        element.addChild(detailText);

        element.alpha = 0;

        //
        const obj = {
            detail: detail,
            element: element,
            detailName: detailName,
            detailText: detailText,
            box: box,
            arrow: arrow,
            image: image
        }

        return obj;
    }

    //dragEvents
    onDragStart = (event) => {
        if (!this.mainContainer.dragging) {
            this.mainContainer.data = event.data;
            this.mainContainer.dragging = true;
    
            this.mainContainer.dragPoint = event.data.getLocalPosition(this.mainContainer.parent);
            this.mainContainer.dragPoint.x -= this.mainContainer.x;
            this.mainContainer.dragPoint.y -= this.mainContainer.y;
        }
    }
    
    onDragEnd = () => {
        if (this.mainContainer.dragging) {
            this.mainContainer.dragging = false;
 
            // set the interaction data to null
            this.mainContainer.data = null;
        }
    }
    
    onDragMove = () => {
        if (this.mainContainer.dragging) {
            const newPosition = this.mainContainer.data.getLocalPosition(this.mainContainer.parent);

            const posX = newPosition.x - this.mainContainer.dragPoint.x;
            const posY = newPosition.y - this.mainContainer.dragPoint.y;

            // console.log(posX);

            if(posX > -this.dragBorders.x && posX < 0) {
                this.mainContainer.x = posX;
            }
        
            if(posY > -this.dragBorders.y && posY < 0) {
                this.mainContainer.y = posY;
            }

        }
    }
    //mm event
    getPlace(event) {
        const click = event.data.global;

        const plc = {
            x: parseInt((click.x/this.width) * this.hoverScale.x),
            y: parseInt((click.y/this.height) * this.hoverScale.y)
        }

        const place = (this.hoverScale.x * plc.y) + plc.x;

        return place;
    }

    mouseOver(event) {

        const place = this.getPlace(event);

        let found = false;

        for(const area of this.areaElements) {

            if(area.alpha[place] > 0) {

                found = true;

                if(!area.active) {
                    this.setState({
                        activeArea: area.area.areaId
                    })

                    area.active = true;
                    area.element.alpha = 1;
                }
                
            } else {
                area.active = false; 
                area.element.alpha = 0;
            }
            
        }

        if(!found) {
            this.setState({
                activeArea: ''
            })
        }

    }

    updateHover = (areaName) => {
        this.setState({
            activeArea: areaName
        })

        for(const area of this.areaElements) {

            if(area.area.areaId === areaName) {
                area.element.alpha = 1;
            } else {
                area.element.alpha = 0; 
            }
                
        }
    }
    //
    componentWillUnmount() {
        this.app.stage && this.app.stage.destroy();

        this.app.stop();

        window.removeEventListener('resize', this.resize);
    }

    resize = () => {
        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        this.width = this.canvasRef.offsetWidth;
        this.height = this.canvasRef.offsetHeight;

        this.sceneInit();  
    }

    render() {
        return (
            <React.Fragment>
                <div className="viewer-container" ref={(DOMNodeRef) => { this.canvasRef=DOMNodeRef; }} style={{height: `${this.containerHeight}px`}}>
                    {/* <Legend activeArea={this.state.activeArea} updateHover={this.updateHover}/> */}
                    {/* <div className='mobile-eye-icon'>
                        <img alt="zoom" src={require("../../../../assets/images/eye.svg")}></img>
                    </div> */}
                </div>

                <MobileView scene={this.scene} changePage={this.changePage} updateHover={this.updateHover}/>
            </React.Fragment>
        );
    }
}

export default Viewer;
