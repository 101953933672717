import React, { Component } from 'react';
import Preloader from './preloader/Preloader';
import Frontpage from './frontpage/Frontpage';
import Aboutpage from './aboutpage/Aboutpage';
import Podpage from './podpage/Podpage';
import Overview from './overview/Overview';
import './main.scss';

class Main extends Component {
    constructor(props) {
        super(props);

        this.state = {
            preloaderLoaded: false,
            preloaderAnimation: false,
            frontClass: '',
            activePage: 'overview', // frontpage\ aboutpage\ podpage\ overview,
            podPointId: 'point-12'
        };

        this.pageTitles = {
            frontpage: 'Piazza',
            aboutpage: 'Explore Pod Park',
            podpage: 'Pod Info',
            overview: 'About Pod Park'
        }

        this.listener();
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                preloaderAnimation: true
            });
        }, 200);
    }

    listener() {
        window.onload = () => {
            setTimeout(() => {
                this.setState({
                    preloaderLoaded: true,
                    preloaderAnimation: false
                });

                this.preloadInit();
            }, 2500);
        };
    }
    

    preloadInit = () => {
        setTimeout(() => {

            this.setState({
                frontClass: 'active'
            });

        }, 850);
    }

    changePage = (page) => {

        if(this.state.activePage !== page.page) {

            this.setState({
                preloaderLoaded: false,
                preloaderAnimation: true,
                frontClass: '',
                activePage: page.page,
                podPointId: page.point || 'null',
                aboutPageSlide: page.aboutPageSlide || ''
            });
            
            document.title = this.pageTitles[page.page];

             //need to change
             setTimeout(() => {
    
                this.setState({
                    preloaderLoaded: true,
                    preloaderAnimation: false
                });
    
                this.preloadInit();
    
            }, 2000);

        }
        
    }

    getPage(state) {

        switch (state.activePage) {
            case 'frontpage': 
                return <React.Fragment><Frontpage class = {state.frontClass} changePage = {this.changePage}/></React.Fragment>;
            case 'aboutpage':
                return <React.Fragment><Aboutpage class = {state.frontClass} aboutPageSlide = {state.aboutPageSlide} changePage = {this.changePage}/></React.Fragment>;
            case 'podpage':
                return <React.Fragment><Podpage class = {state.frontClass} podPointId = {state.podPointId} changePage = {this.changePage}/></React.Fragment>
            case 'overview':
                return <React.Fragment><Overview class = {state.frontClass} changePage = {this.changePage}/></React.Fragment>
            default: 
                return <React.Fragment><Frontpage class = {state.frontClass} changePage = {this.changePage}/></React.Fragment>;
        }

    }

    render() {
        
        return (
            <div className="main">
                <Preloader preloaderLoaded = {this.state.preloaderLoaded} preloaderAnimation = {this.state.preloaderAnimation}/>
                {this.getPage(this.state)}
            </div>
        );
    }
}

export default Main;
