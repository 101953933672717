import React, { Component } from 'react';

import Header from '../ui/header/Header';

import Titleblock from './titleblock/Titleblock';
import Detailsblock from './detailsblock/Detailsblock';


class Overview extends Component {
    constructor(props) {
        super(props);

        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;

        this.isMobile = this.screenWidth > 1024 ? false : true;

        this.state = {
            headerClass: '',
            imagesSlideState: {
                first: 0,
                second: 0
            }
        };

        if(this.isMobile) {
            this.state.imagesSlideState = {
                first: 100,
                second: 100
            };
        }

        this.containerRef = React.createRef();
        this.currentStep = 0;
    }

    changePage = (page) => {
        if(page && this.props.changePage) {
            if(page.page === 'overview') {
                this.scollScreen();
            } else {
                this.props.changePage(page);
            }
        } 
    }

    scollScreen() {
        const start = this.containerRef.current.scrollTop;
        const goal = this.screenHeight * 0.75;
        const dist = goal - start;

        const interval = setInterval(() => {
            this.currentStep += 0.05;

            const currentScroll = start + dist * this.currentStep;

            this.containerRef.current.scrollTop = currentScroll;

            if(this.currentStep >= 1) {
                this.currentStep = 0;
                clearInterval(interval);
            }
        }, 20);

    }

    handleScroll = (event) => {
        const top = event.target.scrollTop;

        //
        let firstSlide = 0;
        const firstSlideTop = this.screenHeight*0.05;
        const firstSlideBottom = this.screenHeight*0.5;

        let secondSlide = 0;
        const secondSlideTop = this.screenHeight*0.5;
        const secondSlideBottom = this.screenHeight*0.95;

        if(!this.isMobile) {

            if(top >= firstSlideTop && top <= firstSlideBottom) {
                firstSlide = 100 * (top - firstSlideTop)/(firstSlideBottom - firstSlideTop);
            } else if(top > firstSlideBottom) {
                firstSlide = 100;
            }
    
            if(top >= secondSlideTop && top <= secondSlideBottom) {
                secondSlide = 100 * (top - secondSlideTop)/(secondSlideBottom - secondSlideTop);
            } else if(top > secondSlideBottom) {
                secondSlide = 100;
            }
            
        } else {
            firstSlide = 100;
            secondSlide = 100;
        }
        
        //
        let headerClass = '';

        if (top > 0) {
            headerClass = 'scrolled';
        } 

        this.setState({
            headerClass: headerClass,
            imagesSlideState:  {
                first: firstSlide,
                second: secondSlide
            }
        })
    }

    render() {
        return (
            <div ref={this.containerRef} className={`overview-container ${this.props.class}`} onScroll={this.handleScroll}>
                
                <Header changePage={this.changePage} headerClass={this.state.headerClass}/>

                <Titleblock />
                <Detailsblock imagesSlideState={this.state.imagesSlideState}/>

            </div>
        );
    }
}

export default Overview;
