import React, { Component } from 'react';

class Divider extends Component {
    render() {
        return (
            <div className="dividers-container">
                <div className="divider-horizontal step-1"></div>
                <div className="divider-horizontal step-2"></div>
                <div className="divider-vertical step-1"></div>
                <div className="divider-vertical step-2"></div>
                <div className="divider-vertical step-3"></div>
                <div className="divider-vertical step-4"></div>
            </div>
        );
    }
}

export default Divider;
