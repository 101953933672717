import React, { Component } from 'react';
import Contact from './contact/Contact';
import Logo from './logo/Logo';
import Navigation from './navigation/Navigation';

class Header extends Component {
    changePage = (page) => {
        if(page && this.props.changePage) {
            this.props.changePage(page);
        }
    }

    render() {
        return (
            <div className={`header-container ${this.props.headerClass}`}>
                <Logo changePage={this.changePage}/>
                <Navigation changePage={this.changePage}/>
                <Contact />
            </div>
        );
    }
}


export default Header;
