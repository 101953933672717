import React, { Component } from 'react';
// import Footer from '../../ui/footer/Footer';
import Divider from '../../ui/divider/Divider';

class Detailsblock extends Component {
    render() {
        return (
            <div className="details-block-container">
                <Divider />
                <div className="first-block">
                    <div className="left-block">
                        <div className="text-container">
                            The latest addition to the mixed-use development at The Piazza, Pod Park introduces a new approach to retail, food and beverage, and recreational programming in Philadelphia. This unique collection offers visitors experiences unlike any existing concept in Philadelphia.
                        </div>
                    </div>
                    <div className="right-block">
                        <div className="animation-mask" style={{width: `${this.props.imagesSlideState.first}%`}}>
                            <div className="image-container"></div>
                        </div>
                    </div>
                </div>
                <div className="second-block">
                    <div className="left-block">
                        <div className="animation-mask" style={{width: `${this.props.imagesSlideState.second}%`}}>
                            <div className="image-container"></div>
                        </div>
                    </div>
                    <div className="right-block">
                        <div className="text-container">
                            Pod Park is a multi-use park with retail spaces made entirely of shipping containers. Divided into four unique zones and inspired by contemporary art, each section has its own theme and varied attractions. From beer-garden-style dining, enormous art installations, to open-air shopping, fitness activities, and family play areas, Pod Park will be the new destination in the region. Find out how you can be a part of Philly’s next innovative movement in dining and recreation.
                        </div>
                    </div>
                    {/* <Footer /> */}
                </div>
            </div>
        );
    }
}

export default Detailsblock;
