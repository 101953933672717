import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';

class Contact extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            formClass: '',
            sendMessageClass: '',

            contactName: '',
            contactEmail: '',
            contactPhone: '',
            contactMessage: '',

            nameValid: true,
            emailValid: true,
            phoneValid: true
        };

        this.inputClass = {
            width: '100%',
            height: '48px',
            marginTop: '0',
            marginBottom: '0'
        };
    }

    openFormPanel = () => {
        this.setState({
            formClass: 'active'
        });
    }

    closeFormPanel = () => {
        this.setState({
            formClass: ''
        });
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.value;
        const id = target.id;

        this.setState({
            [id]: value
        }, () => { this.validateField(id, value)});
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let phoneValid = this.state.phoneValid;

        switch(fieldName) {
            case 'contactName':
                nameValid = value ? true : false;
                break;
            case 'contactEmail':
                emailValid = value ? (value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? true : false) : false; 
                break;
            case 'contactPhone':
                phoneValid = !(value ? value.search(/[a-z,A-Z]/) > -1 : false);
                break;
            default:
                break;
        }

        this.setState({
            formErrors: fieldValidationErrors,
            emailValid: emailValid,
            nameValid: nameValid,
            phoneValid: phoneValid
        });
    }

    sendHandler = () => {
        
        let valid = false;

        let {nameValid, emailValid, phoneValid} = this.state;

        if(!this.state.contactName) {
            nameValid = false;
        }

        if(!this.state.contactEmail) {
            emailValid = false;
        }

        if(!this.state.contactPhone) {
            phoneValid = false;
        }


        if(nameValid && emailValid && phoneValid) {
            valid = true;
        }

      
        

        if(valid) {
            const emailData = {
                name: this.state.contactName,
                email: this.state.contactEmail,
                phone: this.state.contactPhone,
                message: this.state.contactMessage
            };

            this.sendEmail(emailData);

            this.setState({
                sendMessageClass: 'active'
            })
        } else {
            this.setState({
                nameValid: nameValid,
                emailValid: emailValid,
                phoneValid: phoneValid
            })
        }
        
    }

    sendEmail(emailData) {

        const url = './email.php';
        const formData = new FormData();
    
        formData.append('name',     emailData.name);
        formData.append('email',    emailData.email);
        formData.append('phone',    emailData.phone);
        formData.append('message',  emailData.message);
       
        fetch(url, { method: 'POST', mode: 'cors', body: formData })
            .then(function (response) {
                return response.text();
            })
            .then(function (body) {
                console.log(body);
            });
    }

    render() {
        return (
            <React.Fragment>
                <div onClick={this.openFormPanel} className="contact-us-block">
                    <div className="glass"></div>
                    <div className="label-container">
                        <div className="label label-state-1">contact us</div>
                        <div className="label label-state-2">contact us</div>
                    </div>
                </div>
                <div onClick={this.openFormPanel} className="contact-us-block-mobile">
                    <img className="contact-icon-white" alt="logo"></img>
                    <img className="contact-icon-black" alt="logo"></img>
                </div>
                <div className={`contact-form-container ${this.state.formClass}`}>
                    <div onClick={this.closeFormPanel} className="close-button">
                        <img alt="close"></img>
                    </div>

                    <div className="left-block">
                        <div className="center-container">
                            <div className="inputs-panel">
                                <div className="input-row">
                                    <TextField
                                        error={!this.state.nameValid}
                                        id="contactName"
                                        label="Name"
                                        margin="normal"
                                        style={this.inputClass}
                                        value={this.state.contactName}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="input-row">
                                    <TextField
                                        error={!this.state.emailValid}
                                        id="contactEmail"
                                        label="Email"
                                        margin="normal"
                                        style={this.inputClass}
                                        value={this.state.contactEmail}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="input-row">
                                    <TextField
                                        error={!this.state.phoneValid}
                                        id="contactPhone"
                                        label="Phone Number"
                                        margin="normal"
                                        style={this.inputClass}
                                        value={this.state.contactPhone}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                                <div className="input-row">
                                <TextField
                                        id="contactMessage"
                                        label="Message"
                                        margin="normal"
                                        style={this.inputClass}
                                        value={this.state.contactMessage}
                                        onChange={this.handleInputChange}
                                    />
                                </div>
                            </div>
                            <div className="send-button" onClick={this.sendHandler}>SEND</div>
                        </div>
                        <div className={`sent-message-container ${this.state.sendMessageClass}`}>
                            <div className="message-container">
                                <div className="image-container">
                                    <img alt="success"></img>
                                </div>
                                <div className="main-message">
                                    Your message has been sent.
                                </div>
                                <div className="sub-message">
                                    We will get in touch with you as soon as possible. 
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="right-block">
                        <div className="center-container">
                            <div className="title-message">
                                <div className="row-container">
                                    <div className="animation-mask order-1">
                                        <div className="text-container">Contact us</div>
                                    </div>
                                </div>
                                {/* <div className="row-container">
                                    <div className="animation-mask order-2">
                                        <div className="text-container">TOUCH</div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="details-panel">
                                <div className="row-container">
                                    <div className="animation-mask order-1">
                                        <div className="label">Head office:</div>
                                        <div className="value">PH Retail</div>
                                    </div>
                                </div>
                                <div className="row-container">
                                    <div className="animation-mask order-2">
                                        <div className="label">Working time:</div>
                                        <div className="value">Mon - Fri, 9am - 6pm</div>
                                    </div>
                                </div>
                                <div className="row-container">
                                    <div className="animation-mask order-3">
                                        <div className="label">Email:</div>
                                        <div className="value">mail@phretailpartners.com</div>
                                    </div>
                                </div>
                                <div className="row-container">
                                    <div className="animation-mask order-4">
                                        <div className="label">Sales Department:</div>
                                        <div className="value">(215) 710-8185</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="divider"></div>
                </div>
            </React.Fragment>
        );
    }
}

export default Contact;
