import React, { Component } from 'react';

class Navigation extends Component {
    render() {
        
        return (
            <React.Fragment>
                 <div className="overview-button" onClick={() => {this.props.changePage({page: 'overview'})}}>
                    <div className="label-container">
                        About Pod Park
                    </div>
                </div>
                <div className="about-park-button" onClick={() => {this.props.changePage({page: 'aboutpage'})}}>
                    <div className="label-container">
                        Explore Pod Park
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Navigation;
