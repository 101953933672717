import React, { Component } from 'react';

import Header from '../ui/header/Header';

// import Slider from './slider/Slider';
// import Welcomeblock from './welcomeblock/Welcomeblock';
import Landscape from './landscape/Landscape';
// import Descriptionblock from './descriptionblock/Descriptionblock';

class Aboutpage extends Component {
    constructor(props) {
        super(props);

        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth;

        this.isMobile = this.screenWidth > 1024 ? false : true;

        this.containerRef = React.createRef();

        this.state = {
            headerClass: '',
            imagesSlideState: {
                first: 0,
                second: 0
            }
        };
    }

    changePage = (page) => {
        if(page && this.props.changePage) {
            this.props.changePage(page);
        }
    }

    handleScroll = (event) => {
        const top = event.target.scrollTop;

        //
        let firstSlide = 0;
        const firstSlideTop = this.screenHeight*1.1;
        const firstSlideBottom = this.screenHeight*1.6;

        let secondSlide = 0;
        const secondSlideTop = this.screenHeight*1.6;
        const secondSlideBottom = this.screenHeight*2.05;

        if(!this.isMobile) {
            if(top >= firstSlideTop && top <= firstSlideBottom) {
                firstSlide = 100 * (top - firstSlideTop)/(firstSlideBottom - firstSlideTop);
            } else if(top > firstSlideBottom) {
                firstSlide = 100;
            }
    
            if(top >= secondSlideTop && top <= secondSlideBottom) {
                secondSlide = 100 * (top - secondSlideTop)/(secondSlideBottom - secondSlideTop);
            } else if(top > secondSlideBottom) {
                secondSlide = 100;
            }
        } else {
            firstSlide = 100;
            secondSlide = 100;
        }
        
         
        //
        let headerClass = '';

        if (top > 0) {
            headerClass = 'scrolled';
        } 

        this.setState({
            headerClass: headerClass,
            imagesSlideState:  {
                first: firstSlide,
                second: secondSlide
            }
        })
    }

    componentDidMount() {
        //autoscroll to map part (for old version of this page)
        // if(this.props.aboutPageSlide === 'landscape') {
        //     this.containerRef.current.scrollTop = this.screenHeight - 30;
        // }
    }

    render() {
        return (
            <div ref={this.containerRef} className={`about-page-container ${this.props.class}`}>
                
                {/* <Slider /> */}

                <Header changePage={this.changePage} headerClass={this.state.headerClass}/>

                {/* <Welcomeblock /> */}

                <Landscape changePage={this.changePage}/>

                {/* <Descriptionblock imagesSlideState={this.state.imagesSlideState}/> */}

            </div>
        );
    }
}

export default Aboutpage;
