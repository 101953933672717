import React, { Component } from 'react';

class Logo extends Component {
    render() {
        
        return (
            <React.Fragment>
                <div className="logo-container" onClick={() => {this.props.changePage({page: 'overview'})}}>
                    <img className="logo-white" alt="logo"></img>
                    <img className="logo-black" alt="logo"></img>
                </div>
            </React.Fragment>
        );
    }
}

export default Logo;
