import React, { Component } from 'react';
import Viewer from './viewer/Viewer';

class Landscape extends Component {
    constructor(props) {
        super(props);

        this.state = {
            height: '100vh'
        }
    }

    updateHeight = (height) => {

        this.setState({
            height: height
        });

    }

    changePage = (page) => {
        if(page && this.props.changePage) {
            this.props.changePage(page);
        }
    }

    render() {
        return (
            <div className="landscape-container">
                <Viewer updateHeight={this.updateHeight} changePage={this.changePage}/>
            </div>
        );
    }
}

export default Landscape;
