import React, { Component } from 'react';

class Background extends Component {
    constructor(props) {
        super(props);

        this.state = {
            top: '-50%',
            left: '-50%'
        }

        this.screenWidth = window.innerWidth;
        this.screenHeight = window.innerHeight;

        this.screenWidthCenter = this.screenWidth/2;
        this.screenHeightCenter = this.screenHeight/2;

        this.listener();
    }

    listener() {

        document.addEventListener('mousemove', this.mouseMove);

    }

    mouseMove = (e) => {
        // const factor = 15;

        // const valueX = -((e.clientY - this.screenHeightCenter)/this.screenHeight) * factor;
        // const valueY = ((e.clientX - this.screenWidthCenter)/this.screenWidth) * factor;

        const offset = {
            x: 50 - 4*((e.clientX - this.screenWidthCenter)/this.screenWidthCenter),
            y: 50 - 4*((e.clientY - this.screenHeightCenter)/this.screenHeightCenter)
        }
        
        this.setState({
            left: '-' + offset.x + '%',
            top: '-' + offset.y + '%',
            // rotateX: valueX,
            // rotateY: valueY
        })
    }

    componentWillUnmount() {
        document.removeEventListener('mousemove', this.mouseMove);
    }

    render() {
        return (
            <div className="background-container">
                <div className="background-overlay-top"></div>
                <div className="background-overlay-bottom"></div>
                <div className="background-overlay"></div>
                <div className="background-images-container" style={{transform: `translate3d(${this.state.left}, ${this.state.top}, 0)`}}></div>
            </div>
        );
    }
}

export default Background;
